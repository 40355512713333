import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Message, Progress } from 'semantic-ui-react';
import { downloadFile } from 'utils/common';
import { getApiFailMessage, getPostData, isEmptyOrNull } from 'utils';
import { downloadBillabilityData } from 'redux/actions/tableauStackAction';
import { updateToastNotificationDetails } from 'redux/actions';
import Button from 'components/common/button/Button';
import Icon from 'components/common/icon';
import './KTDownloads.scss';
import { onDownloadButtonClick } from 'config/analytics';
import CONFIG from 'config/configProps';

const KTDownloads = props => {
  const appliedFilter = useSelector(state => state.filterDataPt.filters);
  const memberList = useSelector(
    state => state.filterDataPt.fetchTeamMemberList?.results || []
  );
  const dispatch = useDispatch();
  const {
    reportName,
    showDownloadModal,
    url,
    passAllValue,
    isAnalystUser,
    loggedInId,
    selectedFilters,
  } = props;
  const [downLoadStatus, setDownloadStatus] = useState({
    downloading: false,
    percentCompleted: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const { percentCompleted, downloading } = downLoadStatus;
  const downloadBillability = useCallback(
    selectedFiltersVal => {
      onDownloadButtonClick(
        `${props.reportName} Download Button}`,
        props.pageUrl
      );
      let postData = getPostData(appliedFilter, memberList, isAnalystUser);
      if (isEmptyOrNull(postData)) return;
      if (isAnalystUser) postData.selectedUserId = [loggedInId];
      if (passAllValue) {
        postData = { ...appliedFilter };
        delete postData.selectedUserId;
      }
      if (!isEmptyOrNull(selectedFiltersVal)) {
        postData['utilizationCategories'] =
          selectedFiltersVal[CONFIG.CHARGES_FILTERS_COLUMNS[0]];
        postData['projectTypes'] =
          selectedFiltersVal[CONFIG.CHARGES_FILTERS_COLUMNS[1]];
      }
      setDownloadStatus({ downloading: true, percentCompleted: 0 });
      dispatch(downloadBillabilityData(postData, setDownloadStatus, url))
        .then(response => {
          downloadFile(
            response.data,
            `${reportName}.xlsx`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
        })
        .catch(error => {
          const { status, data } = error;
          dispatch(
            updateToastNotificationDetails(
              getApiFailMessage(status, data?.detailErrorMessage)
            )
          );
        })
        .finally(() => {
          setDownloadStatus({ downloading: false, percentCompleted: 100 });
        });
    },
    [dispatch, appliedFilter, reportName, url]
  );

  useEffect(() => {
    if (percentCompleted >= 100) {
      setTimeout(() => setShowModal(false), 500);
    }
  }, [percentCompleted]);

  return (
    <>
      <div className="download-container">
        <Button
          onClick={() => {
            showDownloadModal ? setShowModal(true) : downloadBillability();
          }}
          disabled={downloading}
          className="download-container__btn"
          icon={<Icon name="download" />}
          content={downloading ? 'Downloading' : 'Download'}
        />
      </div>
      {showDownloadModal && (
        <Modal
          className="download-modal"
          size="tiny"
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <Modal.Header className="download-modal__header">
            Download billability data
          </Modal.Header>
          {downloading && (
            <Progress
              percent={percentCompleted}
              color="green"
              size="tiny"
              attached="bottom"
              active
            />
          )}
          <Modal.Content className="download-modal__content">
            <Message info className="download-modal__content__info">
              Download billability data for your own analysis. Validate your
              date and filter settings and click Download. Large requests can
              take several minutes to process.
            </Message>
          </Modal.Content>
          <Modal.Actions className="download-modal__footer">
            <Button
              onClick={() => setShowModal(false)}
              className="download-modal__footer__cancel-btn"
              icon={<Icon name="cancel" />}
              disabled={downloading}
              content="Cancel"
            />
            <Button
              onClick={() => downloadBillability(selectedFilters)}
              className="download-modal__footer__download-btn"
              icon={!downloading && <Icon name="download" />}
              disabled={downloading}
              content={downloading ? 'Downloading...' : 'Download'}
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
export default KTDownloads;
