import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';
import { isEmptyOrNull, getPostData, isAnalyst } from 'utils';
import './index.scss';
import { isEqual, formatDate, isLoading } from 'utils/common';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';
import { selectors } from 'redux/reducers';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

const KTTimeSheetTable = props => {
  const {
    appliedFilter,
    memberList,
    loggedInUserRole,
    timesheetData,
    appliedCollection,
  } = props;
  const [apiData, setApiData] = useState(timesheetData);
  const [showTooltip, setShowToolTip] = useState({});

  const history = useHistory();
  if (isAnalyst(loggedInUserRole)) {
    history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    getApiData();
  }, [appliedFilter]);

  useEffect(() => {
    setApiData(timesheetData);
  }, [timesheetData]);

  const getApiData = () => {
    let dataToPost = getPostData(appliedFilter, memberList);
    dataToPost['selectedUserId'] =
      appliedCollection?.selectedUserId || dataToPost?.selectedUserId || [];
    if (isEmptyOrNull(dataToPost)) return;
    props.fetchTimesheetData(
      API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA,
      dataToPost
    );
  };

  const renderBillabilityChart = () => {
    let data = apiData?.data || {};
    let headerColumns = data?.allWeeks || [];
    let employeesData = data?.employees || {};
    let keys = Object.keys(headerColumns);
    return (
      <div className="timeSheet-table">
        <div className="timeSheet-table__inner">
          <table className="timeSheet-table__inner__main">
            <tr className="timeSheet-table__inner__main__header">
              <th className="header-1">Name</th>
              <th className="header-2">On-Time</th>
              {keys.map(key => {
                return (
                  <td className="heading" key={key}>
                    {formatDate(key, 'MMM-dd, yyyy')}
                  </td>
                );
              })}
            </tr>
            {Object.keys(employeesData).map(key => {
              const val = employeesData[key];
              return (
                <tr className="timeSheet-table__inner__main__body" key={key}>
                  <th className="header-1">{key}</th>
                  <th className="header-2">{`${val.onTimePercentage}%`}</th>
                  {keys.map((columsKey, index) => {
                    if (!isEmptyOrNull(val.weeklyDetails)) {
                      const weeklyDetails = val.weeklyDetails.find(
                        e => e.weekPostedDate === columsKey
                      );
                      if (!isEmptyOrNull(weeklyDetails)) {
                        const tooltipClassName = showTooltip[
                          `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                        ]
                          ? 'on'
                          : 'off';
                        return (
                          <td
                            key={columsKey}
                            className={`value ${
                              !isEqual(
                                weeklyDetails.hoursSubmitted,
                                weeklyDetails.theoCapHrs
                              ) && !isEqual(weeklyDetails.hoursSubmitted, '0')
                                ? 'highlighted'
                                : ''
                            } ${
                              isEqual(weeklyDetails.hoursSubmitted, '0')
                                ? 'late'
                                : ''
                            }`}
                          >
                            <span
                              className="tooltip-with-icon periodTooltip desc"
                              onMouseEnter={() => {
                                const newState = {};
                                newState[
                                  `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                ] = true;
                                setShowToolTip({ ...newState });
                              }}
                              onMouseLeave={() => {
                                const newState = {};
                                newState[
                                  `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                ] = false;
                                setShowToolTip({ ...newState });
                              }}
                            >
                              <div>
                                {isEqual(weeklyDetails.hoursSubmitted, '0')
                                  ? 'Late'
                                  : weeklyDetails.hoursSubmitted}
                              </div>
                              <div id="tooltip" className={tooltipClassName}>
                                <div className="tooltip-inner">
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Week Posted Date:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.weekPostedDate}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Hours Submitted:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.hoursSubmitted}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Theo Cap Hrs:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.theoCapHrs}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Status:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.status}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </td>
                        );
                      } else {
                        return <td className="grey" key={columsKey} />;
                      }
                    }
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {isLoading(apiData) ? (
          <KTBillabilityLoader />
        ) : (
          renderBillabilityChart()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    loggedInUserRole: store.homePageData.loggedInUserRole,
    timesheetData: {
      isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
      data: selectors.apiSelector.getResults(
        store,
        API_CALL_IDENTIFIERS.FETCH_TIMESHEET_DATA
      ),
    },
    appliedCollection: store.filterDataPt.appliedCollection,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTimesheetData: bindActionCreators(
      actions.apiActions.fetchApiData,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KTTimeSheetTable);
