import CONFIG from 'config/configProps';
import { isEqual } from 'utils/common';

export function getFilters(roleName, pageName, selectedIndex) {
  const isHomePage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.HOME);
  const isBillabilityPage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.BILLING);
  const isTimesheetPage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.TIMESHEET);
  const isChargesPage = isEqual(pageName, CONFIG.PROTOTYPE_URLS.CHARGES_HOURS);
  const defaultFilters = {
    name: true,
    status: true,
    jobTitle: true,
    location: true,
    managementModel: true,
    geoBusiness: true,
    practiceArea: true,
    path: true,
  };
  // Define your filters here
  const filters = {
    ktd_super_admin: {
      homePage: {
        0: { ...defaultFilters },
      },
      billabilityPage: {
        0: {
          ...defaultFilters,
        },
        1: {
          ...defaultFilters,
        },
      },
    },
    ktd_leader: {
      homePage: {
        0: {
          ...defaultFilters,
        },
      },
      billabilityPage: {
        0: {
          ...defaultFilters,
        },
        1: {
          ...defaultFilters,
        },
      },
    },
    ktd_manager: {
      homePage: {
        0: { ...defaultFilters },
      },
      billabilityPage: {
        1: {
          ...defaultFilters,
        },
        0: {
          managementModel: true,
          geoBusiness: true,
          practiceArea: true,
          path: true,
        },
      },
    },
  };
  let filtersToShow = {};
  if (isHomePage)
    filtersToShow = filters[roleName]['homePage'][selectedIndex] || {};
  if (isBillabilityPage)
    filtersToShow = filters[roleName]['billabilityPage'][selectedIndex] || {};
  if (isTimesheetPage) filtersToShow = { ...defaultFilters };
  if (isChargesPage) filtersToShow = { ...defaultFilters };
  return filtersToShow;
}
