import React, { useEffect, useState } from 'react';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import TreeSelect from 'components/KTVerticalDd';
import Icon from 'components/common/icon/Icon';
import KTTimePeriod from 'components/KTTimePeriod';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';
import {
  dropdownFormat,
  getDefaultSelectedPa,
  isBusinessView,
  isEmptyOrNull,
  isLeaderRole,
  isManagerUser,
  isMemberView,
} from 'utils';
import { cloneDeep, isEmpty, isEqual } from 'utils/common';
import './index.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFilters } from './KTFilterShowHide';
import { getWeekPostedDates } from 'utils/weekPostedDates';
import { formatPracticeAreaData, seperatePaTopicSubTopicData } from 'utils/dd';
import { useSelector } from 'react-redux';

const KTFilters = props => {
  const appliedCollection = useSelector(
    state => state.filterDataPt.appliedCollection
  );
  const collectionList = useSelector(
    state => state.filterDataPt.fetchCollectionData?.results || {}
  );
  const {
    isFilterOpen,
    selectedBusinessMemberId,
    defaultUserFilters,
    defaultValue,
    onSelectionChange,
    onDateSelectionChange,
    onTreeSelectApply,
    // updateFilterState,
    isHomePage,
    isBusinessComparisonPage,
    loggedInUserRole,
  } = props;
  /** Define Intial values */
  const FILTERS = CONFIG.PROTOTYPE_FILTERS;
  const loadBusinessComparisonFilters =
    isBusinessComparisonPage && isLeaderRole(loggedInUserRole);
  const selectedTab = isHomePage
    ? CONFIG.PAGE_TABS[loggedInUserRole][props.selectedIndexFromStore]
    : '';
  const isMemberViewOpen = isHomePage ? isMemberView(selectedTab) : false;
  const isBusinessViewOpen = isHomePage ? isBusinessView(selectedTab) : false;
  const history = useHistory();
  const weekPostedData = getWeekPostedDates(
    defaultValue.startDate,
    defaultValue.endDate
  );
  const isManager = isManagerUser(loggedInUserRole);
  /**initial states */
  const [filterState, setFilterState] = useState({});
  const [practiceAreaData, setPracticeAreaData] = useState({});
  const [paDataForManager, setPaDataForManager] = useState({});
  const [paTopicsSubtopicsForManager, setPaTopicsSubtopicsForManager] =
    useState({});

  useEffect(() => {
    if (isBusinessComparisonPage) {
      setFilterState(props.filterStateBc || {});
    } else {
      setFilterState(props.filterState || {});
    }
  }, [isBusinessComparisonPage]);

  useEffect(() => {
    if (!isEmpty(appliedCollection)) {
      let newState = {};
      newState[`${FILTERS.NAME.field}Length`] =
        appliedCollection?.selectedUserId.length;
      newState[`${FILTERS.NAME.field}Selected`] =
        appliedCollection?.selectedUserId;
      setFilterState(newState);
      props.applyCollection();
    }
  }, [appliedCollection]);

  const locationCopy = cloneDeep(
    loadBusinessComparisonFilters
      ? props.locationListBc.data
      : props.locationList.data
  );
  const memberList = loadBusinessComparisonFilters
    ? props.memberListBc?.data
    : props.memberList?.data;

  const geoLocationList = isBusinessComparisonPage
    ? props.geoLocationListBc.data
    : props.geoLocationList.data;

  const pageName = history.location.pathname;
  const isBillRatePageOpen =
    isEqual(pageName, CONFIG.PROTOTYPE_URLS.CHARGES_HOURS) &&
    isEqual(history.location.state?.optionIndex, 1);
  const filtersToShow = getFilters(
    loggedInUserRole,
    pageName,
    props.selectedIndexFromStore
  );
  const weekPostedDataCopy = cloneDeep(weekPostedData);

  useEffect(() => {
    const practiceAreaCopy = loadBusinessComparisonFilters
      ? props.practiceAreaListBc
      : props.practiceAreaList;

    const data = practiceAreaCopy?.data || [];
    if (!isEmptyOrNull(defaultUserFilters?.data)) {
      if (!isEqual(data, practiceAreaData)) {
        setPracticeAreaData(cloneDeep(data));
        if (isManager) {
          const practiceAreaListForManagers = formatPracticeAreaData(
            data,
            defaultUserFilters?.data
          );
          setPaDataForManager(cloneDeep(practiceAreaListForManagers));
          setPaTopicsSubtopicsForManager(
            seperatePaTopicSubTopicData(practiceAreaListForManagers)
          );
        }
      }
    }
  }, [
    props.practiceAreaList?.data,
    props.practiceAreaListBc?.data,
    defaultUserFilters?.data,
    isBusinessComparisonPage,
  ]);

  /** function to render top date filter */
  const renderDateContainer = () => {
    const showSearch = true;
    const showSelectedOnTop = true;
    const hideAllOption = true;
    return (
      <ul className="filters_list">
        {!isBillRatePageOpen && (
          <li>
            <KTTimePeriod
              onSelectionChange={onDateChange}
              isBusinessComparisonPage={isBusinessComparisonPage}
            />
          </li>
        )}
        {!isBillRatePageOpen &&
          renderDdFilter(
            FILTERS.WEEK_POSTED_DATE,
            weekPostedDataCopy,
            false,
            false,
            true
          )}
        {filtersToShow?.name &&
          renderDdFilter(
            FILTERS.NAME,
            getDataValue(memberList, FILTERS.NAME.field),
            false,
            showSearch,
            false,
            showSelectedOnTop,
            true
          )}
        {filtersToShow?.practiceArea
          ? renderDdFilter(
              FILTERS.PRACTICE_AREA,
              isManager ? paDataForManager : practiceAreaData,
              false,
              false,
              true
            )
          : null}
        {!isEmptyOrNull(geoLocationList) &&
          filtersToShow?.geoBusiness &&
          renderDdFilter(
            FILTERS.GEO_BUSINESS,
            getDataValue(geoLocationList),
            hideAllOption
          )}
        {filtersToShow?.path &&
          renderDdFilter(FILTERS.PATH, CONFIG.FILTERS_PATH, hideAllOption)}
        {filtersToShow?.managementModel &&
          renderDdFilter(
            FILTERS.MANAGEMENT_MODEL,
            getDataValue(CONFIG.MANAGEMENT_MODEL),
            hideAllOption
          )}
        {filtersToShow?.jobTitle &&
          renderDdFilter(
            FILTERS.JOB_TITLE,
            getDataValue(
              isBusinessComparisonPage
                ? props.jobTitleListBc.data
                : props.jobTitleList.data
            ),
            false,
            false,
            false,
            showSelectedOnTop
          )}
        {filtersToShow?.location &&
          renderDdFilter(FILTERS.LOCATION, locationCopy, false, false, true)}
        {filtersToShow?.status &&
          renderDdFilter(
            FILTERS.STATUS,
            getDataValue(CONFIG.STATUS),
            hideAllOption
          )}
      </ul>
    );
  };

  /** function to get default text of filter */
  const getSelectedFilterText = (filter, selectedValues) => {
    let selectedText = filter.text;
    let length = filterState[`${filter.field}Length`];
    if (
      !(`${filter.field}Selected` in filterState) &&
      isEqual(filter.field, FILTERS.GEO_BUSINESS.field)
    ) {
      length = !isEmptyOrNull(selectedValues) ? selectedValues.length : 0;
    }
    if (
      !(`${filter.field}Selected` in filterState) &&
      isEqual(filter.field, FILTERS.PRACTICE_AREA.field)
    ) {
      length = !isEmptyOrNull(selectedValues)
        ? Object.keys(selectedValues).length
        : 0;
    }
    return { selectedText, filterLength: length };
  };

  const getSelectedValues = (selectedValue, field) => {
    let selectedValues = [];
    if (isEqual(field, FILTERS.NAME.field) && isEmpty(selectedValue)) {
      if (isMemberViewOpen) selectedValues = props.selectedIdMember;
      else if (isBusinessViewOpen && !isEmptyOrNull(selectedBusinessMemberId))
        selectedValues = selectedBusinessMemberId;
    } else if (
      !(`${field}Selected` in filterState) &&
      isEqual(field, FILTERS.GEO_BUSINESS.field) &&
      !isBusinessComparisonPage
    ) {
      selectedValues = defaultUserFilters?.data?.geoRegion;
    } else if (
      !(`${field}Selected` in filterState) &&
      isEqual(field, FILTERS.PRACTICE_AREA.field) &&
      !isBusinessComparisonPage
    ) {
      selectedValues = getDefaultSelectedPa(
        defaultUserFilters?.data,
        isManager ? paTopicsSubtopicsForManager : props.paTopicSubTopics
      );
    } else selectedValues = selectedValue;
    return selectedValues;
  };

  /** function to render dropdown single select/multiselct, tree structure filter */
  const renderDdFilter = (
    filter,
    dataList,
    hideAllOption,
    showSearch,
    isTreeSelectFilter,
    showSelectedOnTop,
    saveFilter
  ) => {
    const field = filter.field;
    const filterStates = filterState[field];
    let selectedValues = isEmptyOrNull(dataList)
      ? []
      : `${field}Selected` in filterState
      ? filterState[`${field}Selected`]
      : defaultValue[field];
    selectedValues = getSelectedValues(selectedValues, field);
    const { selectedText, filterLength } = getSelectedFilterText(
      filter,
      selectedValues
    );
    const isNameFilter = isEqual(field, FILTERS.NAME.field);
    const isMemberListEmpty =
      isNameFilter &&
      !isEmptyOrNull(dataList) &&
      dataList.length === 1 &&
      dataList[0].value === '00000' &&
      isEmptyOrNull(collectionList);
    return (
      <li key={filter.text}>
        <div
          className="filter-heading"
          onClick={() => {
            const newState = { ...filterState };
            newState[field] = !newState[field];
            setFilterState({ ...newState });
          }}
        >
          <div className="filter-name">
            <div className="text">
              <div>{selectedText}</div>
              {filterLength > 0 && !isMemberViewOpen && (
                <div className="selected-count">{filterLength}</div>
              )}
            </div>
            <Icon name={`angle ${filterStates ? 'up' : 'down'}`} />
          </div>
        </div>

        {filterStates &&
          (isEmptyOrNull(dataList) || isMemberListEmpty ? (
            <div>No Record</div>
          ) : isTreeSelectFilter ? (
            <TreeSelect
              filterName={field}
              dropdownOptions={
                isEqual(field, FILTERS.WEEK_POSTED_DATE.field)
                  ? dataList.reverse()
                  : dataList
              }
              selectedIds={selectedValues}
              applyFilter={onFilterChange}
              filterText={filter.text}
              userRole={loggedInUserRole}
              defaultUserFilters={defaultUserFilters?.data}
              isBusinessComparisonPage={isBusinessComparisonPage}
            />
          ) : (
            <DropDownFilter
              dataList={dataList}
              onChange={onFilterChange}
              selectedList={selectedValues}
              clearFilter={onFilterChange}
              stateField={field}
              isAllSelected={isEqual(
                dataList?.length || 0,
                selectedValues?.length || 0
              )}
              showSearch={showSearch}
              placeHolder={'Search Name'}
              singleSelect={isMemberViewOpen}
              hideButtonFooter={isMemberViewOpen}
              showSelectedOnTop={showSelectedOnTop}
              filterName={filter.text}
              saveFilter={saveFilter}
              hideAllOption={hideAllOption}
            />
          ))}
      </li>
    );
  };

  /** function to format filter list data in single format */
  const getDataValue = (data, field) => {
    if (isEmptyOrNull(data)) return;
    switch (field) {
      case FILTERS.NAME.field:
        return data?.map(({ employeeIdHRO, employeeName }) =>
          dropdownFormat('' + employeeIdHRO, employeeName)
        );
      default:
        return data?.map(el => dropdownFormat('' + el, el));
    }
  };

  /** action to perform on change of filter */
  const onFilterChange = (
    dropdownIdentifier,
    selectedValue,
    length,
    selectedName,
    isTreeChange,
    treeSelectedValues
  ) => {
    let newState = { ...filterState };
    if (!isEqual(dropdownIdentifier, FILTERS.NAME.field)) {
      newState[FILTERS.NAME.field] = false;
      newState[`${FILTERS.NAME.field}Length`] = 0;
      newState[`${FILTERS.NAME.field}Selected`] = [];
    }
    switch (dropdownIdentifier) {
      case FILTERS.PATH.field:
      case FILTERS.MANAGEMENT_MODEL.field:
      case FILTERS.GEO_BUSINESS.field:
      case FILTERS.PRACTICE_AREA.field:
      case FILTERS.STATUS.field:
        newState[`${FILTERS.LOCATION.field}Length`] = 0;
        newState[`${FILTERS.JOB_TITLE.field}Length`] = 0;
        newState[`${FILTERS.LOCATION.field}Selected`] = [];
        newState[`${FILTERS.JOB_TITLE.field}Selected`] = [];
        newState[FILTERS.LOCATION.field] = false;
        newState[FILTERS.JOB_TITLE.field] = false;
        break;
      default:
        newState = { ...filterState };
    }
    newState[dropdownIdentifier] = false;
    newState[`${dropdownIdentifier}Length`] = length;
    newState[`${dropdownIdentifier}Selected`] = isTreeChange
      ? treeSelectedValues
      : selectedValue;
    newState['selectedName'] = selectedName;
    setFilterState({ ...newState });
    isBusinessComparisonPage
      ? props.actions.updateFilterStateBc(newState)
      : props.actions.updateFilterState(newState);
    let passPA = false,
      passGeo = false;
    if (
      !isEqual(dropdownIdentifier, FILTERS.PRACTICE_AREA.field) &&
      !(FILTERS.PRACTICE_AREA.field in filterState) &&
      !isBusinessComparisonPage
    )
      passPA = true;
    if (
      !isEqual(dropdownIdentifier, FILTERS.GEO_BUSINESS.field) &&
      !(FILTERS.GEO_BUSINESS.field in filterState) &&
      !isBusinessComparisonPage
    )
      passGeo = true;
    if (isTreeChange)
      onTreeSelectApply(dropdownIdentifier, selectedValue, passPA, passGeo);
    else onSelectionChange(dropdownIdentifier, selectedValue, passPA, passGeo);
  };

  const onDateChange = (
    startDate,
    endDate,
    filterType,
    timePeriodVal,
    isDateChanged
  ) => {
    if (isDateChanged) {
      let newState = { ...filterState };
      let weekPostedDate = FILTERS.WEEK_POSTED_DATE.field;
      newState[weekPostedDate] = false;
      newState[`${weekPostedDate}Length`] = length;
      newState[`${weekPostedDate}Selected`] = [];
      setFilterState({ ...newState });
      isBusinessComparisonPage
        ? props.actions.updateFilterStateBc(newState)
        : props.actions.updateFilterState(newState);
      onDateSelectionChange(startDate, endDate, filterType, timePeriodVal);
    }
  };

  const resetFilters = () => {
    setFilterState({});
    props.resetFilters();
  };

  /** function to hide filter section */
  // const renderHideShowButton = (text, toggleClassName, onClickName) => {
  //   return (
  //     <div
  //       className={`toggle-filters ${toggleClassName}`}
  //       onClick={onClickName}
  //     >
  //       <div className={`arrow ${isFilterOpen ? 'left' : 'top'}`} />
  //       <div className="content">{text}</div>
  //     </div>
  //   );
  // };

  /** function to set filter section state */
  // const openFilters = () => {
  //   updateFilterState(false);
  // };

  /** function to set filter section state */
  // const closeFilters = () => {
  //   updateFilterState(true);
  // };
  return (
    <>
      {/* {!isFilterOpen &&
        renderHideShowButton('Show Filters', 'floatLeft', closeFilters)} */}
      <Grid.Column
        width={3}
        className={`Kt_dashboard_body_left ${isFilterOpen ? 'show' : 'hide'}`}
      >
        {/* {isFilterOpen &&
          renderHideShowButton('Hide Filters', 'floatRight', openFilters)} */}
        <div className="filters_container">
          <div className="heading">
            <div className="left">FILTERS</div>
            <div className="right" onClick={() => resetFilters()}>
              Reset
            </div>
          </div>
          {renderDateContainer()}
        </div>
      </Grid.Column>
    </>
  );
};

export default KTFilters;
